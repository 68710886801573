import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { FaImdb } from "react-icons/fa"

const Footer = () => {
  const {
    site: {
      meta: { links },
    },
  } = useStaticQuery(graphql`
    query FooterQuery {
      site {
        meta: siteMetadata {
          links {
            imdb
          }
        }
      }
    }
  `)

  return (
    <div className="container py-12 md:flex md:items-center md:justify-between">
      <ul className="flex justify-center md:order-2">
        <FooterLink href={links.imdb} icon={FaImdb} label="IMDB" />
      </ul>
      <div className="mt-8 md:mt-0 md:order-1">
        <p className="text-sm md:text-base text-gray-700">
          &copy; 2021 Tara Segda. All rights reserved.
        </p>
        <a
          className="text-sm md:text-base text-gray-700"
          target="_blank"
          rel="noreferrer"
          href="https://www.manleyweb.dev"
        >
          site by manleyweb.dev
        </a>
      </div>
    </div>
  )
}

const FooterLink = ({ href, label, icon: Icon }) => {
  return (
    <li className="inline-block">
      <a
        href={href}
        target="_blank"
        rel="noreferrer noopener"
        className="text-gray-500 hover:text-blue-600 transition duration-150 ease-in-out"
      >
        <span className="sr-only">{label}</span>
        <Icon className="w-9 h-9 fill-current" />
      </a>
    </li>
  )
}

export default Footer
